import React, { useState, useEffect } from 'react'
import Card from '../components/Card'
import {ICard} from '../Interfaces/ICard'
import { AiOutlineUp } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";
import { RotatingLines } from  'react-loader-spinner';
// import { Link} from "react-router-dom";
import Header from './Header';

interface InterfaceCat {
    name : string,
    nb : number
}


const Gallery = () => {

    const [list, setList] = useState<ICard[]>([])
    const [categ, setCateg] = useState<InterfaceCat[]>([]);
    const [showCategories, setShowCategories] = useState<boolean>(false);
    const [categSelected, setCategSelected] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    const [lastUpdate, setLastUpdate] = useState<string>('')

	const handleSearch = async (e:React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setKeyword(e.target.value)
        if(e.target.value.length < 3)
            return;
		const apiurl = process.env.REACT_APP_BASE_URL + '/api/search.php?q=' + e.target.value;
        const response = await fetch(apiurl)
        const data = await response.json()
        
        setList(data.results)
	}

    const getCars = async () => {
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/list.php'
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.results)
        setLoading(false)
    }

    const getListCats = async () => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/getCategories.php'
        const response = await fetch(apiurl)
        const data = await response.json()
        setCateg(data.results)
    }

    useEffect(() => {
        getListCats()
    }, [])

    useEffect(() => {
        getCars()
    }, [])

    useEffect(() => {
        getLastUpdate()
    }, [])
    

    const fetchList = async (list) => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/list.php?category=' + list.join(',')
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.results)
    }

    const handleCategory = async(e:React.ChangeEvent<HTMLInputElement>) => {
       
        if(e.target.checked){
            setCategSelected([...categSelected, e.target.value])
            await fetchList([...categSelected, e.target.value])
        }else{      
            const index = categSelected.indexOf(e.target.value);
            categSelected.splice(index,1)
            setCategSelected([...categSelected])
            await fetchList(categSelected)
        }
    }  

    const getLastUpdate = async () => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/getLastUpdate.php'
        const response = await fetch(apiurl)
        const data = await response.json()
        setLastUpdate(data.results.date)
    }



    return (
        <>
        <Header page="listing"/>
     
        <div className='container-fluid' style={{position:'relative'}}>
            <div className="legends">
                <div className="item" style={{background:'rgb(71 20 10 / 94%)'}}></div>Category
            </div>
            <br/>
           
            <div className='row'>
            {
                showCategories ? 
                    <div className='col categories-header'>Categories <AiOutlineUp style={{cursor:'pointer'}} color={"rgb(71 20 10 / 94%)"} onClick={(e) => setShowCategories(!showCategories)}/></div>
                :
                    <div className='col categories-header'>Categories <AiOutlineDown style={{cursor:'pointer'}} color={"rgb(71 20 10 / 94%)"} onClick={(e) => setShowCategories(!showCategories)}/></div>
                }
            </div>
            {
            showCategories ? 
                <div className="row categories show">
                {
                    categ.map((item, i) => (
                        <div key={i} className="col-xl-2 form-check-inline" style={{ margin: '0 20px' }}>
                            <input className="form-check-input" type="checkbox" value={item.name} id="flexCheckChecked" onChange={handleCategory}/>
                            <label className="form-check-label" style={{ margin: '0 5px' }}>
                                {item.name}
                            </label>
                            <label style={{'color' : 'rgb(71 20 10 / 94%)'}}>({item.nb})</label>
                        </div>
                    ))
                }
                </div>
            : 
                <div className="row categories">
                {
                    categ.map((item, i) => (
                        <div key={i} className="col-xl-2 form-check-inline" style={{ margin: '0 20px' }}>
                            <input className="form-check-input" type="checkbox" value={item.name} id="flexCheckChecked" onChange={handleCategory}/>
                            <label className="form-check-label" style={{ margin: '0 5px' }}>
                                {item.name}
                            </label>
                            <label style={{'color' : 'rgb(71 20 10 / 94%)'}}>({item.nb})</label>
                        </div>
                    ))
                }
                </div>
            }
             <RotatingLines
                    strokeColor="rgb(71 20 10 / 94%)"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loading}
                />
            <div className="row">
            {
                list.map((item, i) => (
                    <div className="col" key={i}>
                        <Card title={item.title} description={item.description.substring(0, 150)} image={item.image} link={item.link} category={item.category} label={item.label} pubDate={item.pubDate}/>
                    </div>
                ))
            }
        </div>
    </div>
    </>
    )
}

export default Gallery
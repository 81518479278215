import * as React from 'react';
import {useState, useEffect} from 'react';
import { Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

export interface Props {
	page : string;
}

const Header: React.FC<Props> = ({page}) => {

  	const [loading, setLoading] = useState<boolean>(false);
	const [keyword, setKeyword] = useState<string>('');
	const [lastUpdate, setLastUpdate] = useState<string>('')

	const handleSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value)
	}

	useEffect(() => {
		getLastUpdate()
		
		// Auth.isAuthenticated((val:string) => {
		// 	if(!val)
		// 		window.location.replace("/login");
		// 	else{
		// 		const user = JSON.parse(val);
		// 		setUsername(user.username);
		// 		setIsLogged(true)
		// 	}
		// })
	}, []);

	const getLastUpdate = async () => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/getLastUpdate.php'
        const response = await fetch(apiurl)
        const data = await response.json()
        setLastUpdate(data.results.date)
    }
 
	const createJSON = (e) => {
        
        const url = process.env.REACT_APP_BASE_URL + '/api/save.php';
        const payload = {
            save : 1
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

	const createJSONFEED = (e) => {
        
        const url = process.env.REACT_APP_BASE_URL + '/api/saveFeed.php';
        const payload = {
            save : 1
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

	const createJSONIMAGEQUOTES = (e) => {
        
        const url = process.env.REACT_APP_BASE_URL + '/api/saveImageQuoteFeed.php';
        const payload = {
            save : 1
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

	const createJSONQUOTE = (e) => {
        
        const url = process.env.REACT_APP_BASE_URL + '/api/saveQuotesJson.php';
        const payload = {
            save : 1
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

  return (
	
    <nav className="navbar navbar-expand-lg navbar-dark " style={{ background:'rgb(71 20 10 / 94%)', height:'80px'}}>
		<div className="container-fluid">
			<Link className="navbar-brand" to="/"><h5>Cats Tab</h5></Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse justify-content-between" id="navbarNavDropdown">
				<ul className="navbar-nav">
					
					<li className="nav-item">
						{
							page === 'listing' ? 
							<Link className="nav-link active" to="/">Listing</Link>
						:
							<Link className="nav-link" to="/">Listing</Link>
						}
					</li>	
					<li className="nav-item">
						{
							page === 'update' ? 
							<Link className="nav-link active" to="/update">Update</Link>
						:
							<Link className="nav-link" to="/update">Update</Link>
						}
					</li>
					<li className="nav-item">
						{
							page === 'add-quote' ? 
							<Link className="nav-link active" to="/add-quote">Add Quote</Link>
						:
							<Link className="nav-link" to="/add-quote">Add Quote</Link>
						}
					</li>	
					<li className="nav-item">
						{
							page === 'upload' ? 
							<Link className="nav-link active" to="/upload">Add Quotes images / Memes</Link>
						:
							<Link className="nav-link" to="/upload">Add Quotes images / Memes</Link>
						}
					</li>	
					<li className="nav-item">
						{
							page === 'newcat' ? 
							<Link className="nav-link active" to="/newcat">Add New Cat</Link>
						:
							<Link className="nav-link" to="/newcat">Add New Cat</Link>
						}
					</li>

					
				</ul>
				{
				page === 'newcat' ? 
				<ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSON}>Publish</button> 
					</li>
				</ul>
				: ''
				}
				{
				page === 'update' ? 
				<ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSONFEED}>Publish</button> 
					</li>
				</ul>
				: ''
				}
				{
				page === 'upload' ? 
				<ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSONIMAGEQUOTES}>Publish</button> 
					</li>
				</ul>
				: ''
				}
				{
				page === 'add-quote' ? 
				<ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSONQUOTE}>Publish</button> 
					</li>
				</ul>
				: ''
				}
				{
				page === 'editNewCat' ? 
				<ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
					<li className='nav-item' style={{ margin:'10px'}}>
						<button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSON}>Publish</button> 
					</li>
				</ul>
				: ''
				}
				
				<div className="navbar-nav mr-auto mt-4 mt-lg-0">
					<span style={{color:'#fff', marginRight:'10px'}}>Last import date : {lastUpdate}</span>
					<form className="form my-15 my-lg-0">
						<input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" id="searchkeyword" value={keyword} onChange={handleSearch}/>
					</form>
				</div>
			</div>
		</div>
	</nav>
  );
}

export default Header;

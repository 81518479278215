import {FC} from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Listing from './Pages/Listing'
import Update from './Pages/Update';
import Edit from './Pages/Edit';
import AddQuote from './Pages/AddQuote';
import Upload from './Pages/Upload';
import NewCat from './Pages/NewCat';
import EditNewCat from './Pages/EditNewCat';
import ProtectedRoutes from './components/ProtectedRoutes';
import Login from './Pages/Login';
interface Props{
}

const App:FC<Props> = () => {
  return (
	
	<Router>
		<Routes>
			<Route element={<ProtectedRoutes/>}>	
				<Route path = "/" element={<Listing/>} />
				<Route path = "/update" element={<Update/>} />
				<Route path = "/edit/:id" element={<Edit/>} />
				<Route path = "/add-quote" element={<AddQuote/>} />
				<Route path = "/upload" element={<Upload/>} />
				<Route path = "/newcat" element={<NewCat/>} />
				<Route path = "/newcat/edit/:id" element={<EditNewCat/>} />
			</Route>
			<Route path = "/login" element={<Login/>} />
		</Routes> 
	</Router>
  );
}

export default App;

import React, {useState, useEffect} from 'react'
import Header from '../components/Header'
import { Gallery } from '../Interfaces/IGallery';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from  'react-loader-spinner';
import {MdDelete} from 'react-icons/md'
import {MdEdit} from 'react-icons/md'
import { confirmAlert } from 'react-confirm-alert';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const NewCat = () => {
    const bold = {
        fontWeight : '600'
    }

    const [description, setDescription] = useState('')
    const [cname, setName] = useState('')
    const [gallery, setGallery] = useState('')
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<Gallery[]>([]);
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const handleDescription = (e:React.FormEvent, desc:string) => {
        const target = e.target as HTMLTextAreaElement;
        setDescription(desc)
        if(desc.length < 5){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const isValidUrl = (urlString : string) => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    const handleGallery = (e:React.FormEvent) => {
        const target = e.target as HTMLTextAreaElement
        const url = target.value;
        setGallery(url);
            
        if(url.length < 10 || !isValidUrl(url)){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    };

    const handleName = (event:React.ChangeEvent<HTMLInputElement>) => {
        const n = event.target.value;
        setName(n);
            
        if(n.length < 2){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleSave = () => {
        const data = {
            name : cname,
            description : description,
            gallery : gallery,
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/add.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                reset();
                $('#file_export').DataTable();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    const reset = () => {
        setDescription('');
        setName('');
        setGallery('');
    }

    const handleEdit = (e:React.FormEvent, id : string) => {
        
    }

    const handleDelete = (e:React.FormEvent, id : string) => {
        e.preventDefault();

        confirmAlert({
            title: 'Deletion confirmation',
            message: 'Are you sure you want to delete this cat?',
            buttons: 
            [
                {
                    label: 'Yes',
                    onClick: () => {
                        const endpoint = process.env.REACT_APP_BASE_URL + '/api/deleteCat.php';
                        fetch(endpoint, {
                            "method": "POST",
                            "body": JSON.stringify({id : id})
                        })
                        .then(response => response.json())
                        .then(response => {     
                            if(response.action === "failed"){
                                toast.error(response.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    theme: "colored"
                                });
                            }else{
                                toast.success(response.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    theme: "colored"
                                });
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);                    
                            }
                        })
                        .catch(data => {
                            toast.error(data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                theme: "colored"
                            });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => void{},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        });    
    }

  
    useEffect(() => {
        const url = process.env.REACT_APP_BASE_URL + '/api/getGalleries.php';
        setLoading(true)
        fetch(url, {
            method : "POST",
            body : JSON.stringify({})
        })
        .then(response => response.json())
        .then(data => {
            setList([...data.results])
        })
        .then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    
                    if($('#file_export').length > 0 ){
                        
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
                                order: [[4, 'desc']],
                            });
                            clearInterval(caller)                            
                        }
                    }
                },
                100
            )
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
        
    }, []);

    return (
        <>
        <ToastContainer />
        <Header page="newcat" />
        <div className="container-fluid">
            <>
            {
            showAdd ? 
            <div className="addNewCat">
                <button type="button" id="addnewcat" onClick={(e) => setShowAdd(!showAdd)} className="btn btn-success">Hide Form</button>
             </div>
            :
            <div className="addNewCat">
                <button type="button" id="addnewcat" onClick={(e) => setShowAdd(!showAdd)} className="btn btn-primary">Add New Cat</button>
            </div>
           }
           </>
            {showAdd ? 
            <form className="AddCatForm">
                <div className="row">
                    <div className="form-group mb-3">
                        <label style={bold}>Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="cname" value={cname} onChange={(e) => handleName(e)}/>
                        <div className="invalid-feedback">Provide with a valid name (Min 2 characters)</div>
                        <div className="valid-feedback"></div>
                    </div>
                    <div className="form-group mb-3">
                        <label style={bold}>Description <span className="text-danger">*</span></label>
                        <textarea rows={1} className="form-control small" id="description" value={description} onChange={(e) => handleDescription(e, e.target.value)}></textarea>
                        <div className="invalid-feedback">Provide with a valid description (Min 10 characters)</div>
                        <div className="valid-feedback"></div>
                    </div>
                    <div className="form-group mb-3">
                        <label style={bold}>Gallery URL <span className="text-danger">*</span></label>
                        <textarea rows={1} className="form-control small" id="gallery" value={gallery} onChange={(e) => handleGallery(e)}></textarea>
                        <div className="invalid-feedback">Provide with a valid url for the gallery</div>
                        <div className="valid-feedback"></div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </form>
            : ''
            }
        </div>
        <div className="table-responsive" style={{ margin:'20px' }}> 
            <RotatingLines
                strokeColor="rgb(13, 110, 253)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            {
            loading ? '' :  
            <table id="file_export" className="table table-striped table-bordered display small">
                <thead>
                    <tr>
                        <th>Images</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Gallery</th>
                        <th>Created At</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {
                    list.map((item, i) => (
                        <tr key={i} >
                            <td>
                                <div className="image-dog">
                                    <img width="100" height="auto" src={item.images[0]}/>
                                    <img width="100" height="auto" src={item.images[1]}/>
                                    <img width="100" height="auto" src={item.images[2]}/>
                                </div>
                            </td>
                            <td style={bold}>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.gallery}</td>
                            <td>{item.createdAt}</td>
                            <td><button type="button" className="btn btn-danger"><span onClick={(e) => handleDelete(e, item.id)}><MdDelete/></span></button></td>
                            <td><a href={"/newcat/edit/" + item.id}><button type="button" className="btn btn-success"><span><MdEdit/></span></button></a></td>
                        </tr>              
                    ))    
                }
                </tbody>
                <tfoot>
                    <tr>
                        <th>Images</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Gallery</th>
                        <th>Created At</th>
                        <th></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
            }
        </div>
        </>
    )
}

export default NewCat
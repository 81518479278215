import React from 'react'
import Header from "../components/Header";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast} from 'react-toastify';
import { useParams } from 'react-router-dom';


type CarParams = {
    id : string
}

const EditNewCat = () => {
 
    const { id } = useParams<CarParams>();
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [gallery, setGallery] = useState<string>('');

    const bold = {
        fontWeight : '600'
    }
    const titleRef = useRef<HTMLTextAreaElement>(null);

    const handleSave = (e:React.FormEvent) => {
        e.preventDefault();
        const data = {
            id : id,
            title : title,
            description : description,
            gallery : gallery
        }
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/editNewCat.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    };
    
    useEffect(() => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/getNewCat.php';
        if(titleRef.current !== null)
            titleRef.current.focus()

        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify({id : id})
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                console.log("Error");
            }else{
                setTitle(response.results.name);
                setDescription(response.results.description);
                setGallery(response.results.gallery);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, [id]);

    const handleTitle = (e:React.FormEvent, title:string) => {
        const target = e.target as HTMLTextAreaElement;
        setTitle(title)
        if(title.length < 1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleDescription = (e:React.FormEvent, description:string) => {
        const target = e.target as HTMLTextAreaElement;
        setDescription(description)
        if(description.length < 1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleGallery = (e:React.FormEvent, gallery:string) => {
        const target = e.target as HTMLInputElement;
        setGallery(gallery)
        if(gallery.length < 1 || gallery.search(/https?:\/\//i) === -1){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        } 
    }

    return (
        <div>
            <ToastContainer />
            <Header page="editNewCat"/>
            <div className="container small">
                <form style={{ marginTop:'30px' }}>
                    <div className="container-edit">
                        <div className="edit-item" style={{ width:'100%',margin:'10px' }}>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Name</label>
                                <div className="controls">
                                    <textarea ref={titleRef} rows={1} className="form-control small" value={title} onChange={(e) => handleTitle(e, e.target.value)}></textarea>
                                    <div className="invalid-feedback">The name field is required (Min 5 characters)</div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Description</label>
                                <div className="controls">
                                    <textarea rows={5} className="form-control small" value={description} onChange={(e) => handleDescription(e, e.target.value)}></textarea>
                                    <div className="invalid-feedback">The description field is required (Min 10 characters)</div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>                  
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Gallery Url</label>
                                <input type="text" className="form-control" id="galleryUrl" value={gallery} onChange={(e) => handleGallery(e, e.target.value)}/>
                                <div className="invalid-feedback">The Image Url is required</div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditNewCat